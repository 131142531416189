import axios from 'axios'
import { Toast  } from 'vant'
// import qs from 'querystring'
import router from "@/router";

const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  baseURL: 'https://api.shhualing.com.cn', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000, // request timeout
  headers: {
    'Content-Type': 'application/json'
  }
})

// request interceptor
service.interceptors.request.use(
  config => {
    config.headers['Authorization'] =  'Bearer ' + localStorage.getItem('token')
    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    return response
  },
  error => {
    const { response, request, message} = error;
    if (response) {
      console.log(response)
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const { status, headers} = response;
      // token过期重新登录
      switch (status) {
        case 403:
          // 没有权限的情况
          // console.log(data.message);
          router.push({path:'/login'})
          break;
        case 401:
          // Token有问题的情况
          Toast.fail(response.data.message)
          // refreshToken(0, config);
          console.error('登录信息过期');
          router.push({path:'/login'})
          break;
        case 422:
          console.error('参数校验失败');
          break;
      }
      if (status >= 500) {
        console.error('服务器错误');
      }
      console.log(headers);
    } else if (request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.error('请求错误');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error', message);
    }
    return Promise.reject(error);


    // localStorage.removeItem('token')
    // router.push({path:'/login'})

    // this.$router.push({path:'/login'})
    // if(error.status !== 403 || response.status !== 401 || response.status === 50008 || response.status === 50012 || response.status === 50014 || response.status === 50001){
    //   Toast.fail('登录信息失效')
    //   localStorage.removeItem('token')
    //   this.$router.push({path:'/login'})
    // }
    // return Promise.reject(error)
  }
)

export default service

