import request from '@/request'

// 登录
export function Login(data) {
  return request({
    url: '/token/token/obtain',
    method: 'post',
    data
  })
}

// 获取个人分类配置
export function Meconfig() {
  return request({
    url: '/me/config',
    method: 'get'
  })
}

// 获取设备分类配置
export function Udevice(id) {
  return request({
    url: '/device/'+ id,
    method: 'get'
  })
}

// 获取设备分类配置List
export function deviceConfig(id) {
  return request({
    url: '/me/device-config/'+ id,
    method: 'get'
  })
}

// 获取所有在线设备
export function Overview(params) {
  return request({
    url: 'device/overview',
    method: 'get',
    params
  })
}

// 获取某个设备详细标题
export function Getdevice(params) {
  return request({
    url: '/data/mapping',
    method: 'get',
    params
  })
}

// 获取某个设备详细数据
export function GetdeviceData(data) {
  return request({
    url: '/data/query-latest',
    method: 'post',
    data
  })
}

// 获取某个类详细数据
export function Getchart(data) {
  return request({
    url: '/data/query',
    method: 'post',
    data
  })
}

// 获取故障数据
export function latest(params) {
  return request({
    url: '/latest-event',
    method: 'get',
    params
  })
}

// 获取个人信息
export function me() {
  return request({
    url: '/me',
    method: 'get'
  })
}

// 获取组织
export function Organizationlist(params) {
  return request({
    url: '/rbac-manage/user/overview',
    method: 'get',
    params
  })
}

// 获取所有故障
export function event(params) {
  return request({
    url: '/event',
    method: 'get',
    params
  })
}


// 微信换取Token
export function obtain(data) {
  return request({
    url: '/token/token/obtain/wx',
    method: 'post',
    data
  })
}

// 微信绑定
export function obtainwx(data) {
  return request({
    url: '/me/bind_wx',
    method: 'post',
    data
  })
}

// 微信解绑
export function unbindWx(data) {
  return request({
    url: '/me/unbind_wx',
    method: 'post',
    data
  })
}
